import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PurchaseOrderList, PurchaseOrderWrapper } from '@shared/models/custom/purchase-order.model';
import { SflBaseComponent } from '../sfl-base/sfl-base.component';
import { PurchaseOrderService } from '../../services/purchase-order.service';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { AlertType } from '@shared/models/alert-type.enum';

@Component({
  selector: 'app-list-purchase-order',
  templateUrl: './list-purchase-order.component.html',
  styleUrls: ['./list-purchase-order.component.scss'],
})
export class ListPurchaseOrderComponent extends SflBaseComponent{
  @Input() listOFPurchaseOrder: PurchaseOrderList | Array<any>;
  @Output() purchaseOrder: EventEmitter<PurchaseOrderWrapper> = new EventEmitter();
  @Output() updatePurchaseOrderList: EventEmitter<boolean> = new EventEmitter();
  deletePurchaseOrderID: number;
  showDialogBox = false;
  constructor(private readonly layoutUtilsService: LayoutUtilsService, private readonly purchaseOrderService: PurchaseOrderService) {
    super();
  }



  deletePurchaseOrderPopup(id: number): void {
    this.deletePurchaseOrderID = id;
    this.showDialogBox = true;
  }

  closeModal(): void {
    this.showDialogBox = false;
  }

  deletePurchaseOrder(id): void {
    this.subscriptionManager.add(
      this.purchaseOrderService.purchaseOrderDelete(id).subscribe({
        next: () => {
          this.layoutUtilsService.showActionNotification('Purchase Order updated successfully', AlertType.Success);
          this.deletePurchaseOrderID = null;
          this.closeModal();
          this.updatePurchaseOrderList.next(true)
        },
        error: (e) => {
          this.layoutUtilsService.showActionNotification('There was an error while adding Purchase Order details', AlertType.Error);
        },
      }),
    );
  }

  editPurchaseOrder(PurchaseOrder:PurchaseOrderWrapper) :void{
    this.purchaseOrder.next(PurchaseOrder);
  }
}
