<div class="card card-custom add-contact">
  <app-card-header
    [cardTitle]="cardTitle"
    [cardSubTitle]="cardSubTitle"
    [buttons]="buttons"
    [cardLabelClass]="'mb-0'"
  ></app-card-header>
  <div class="card-body create-card">
    <form
      class="form"
      [formGroup]="createContactForm"
      autocomplete="off"
      novalidate="novalidate"
      id="createContactForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label">Purchase Order Number</label>
            <input
              type="text"
              class="form-control custom"
              required
              placeholder="Enter Purchase Order Number"
              formControlName="po_number"
            />
            <app-form-error
              [validation]="'required'"
              [form]="createContactForm"
              [controlName]="'po_number'"
              [fieldLabel]="'Purchase Order Number'"
            ></app-form-error>
            <app-form-error
            [validation]="'minlength'"
            [form]="createContactForm"
            [controlName]="'po_number'"
            [fieldLabel]="'value'"
            [length]="3"
          ></app-form-error>
            <app-form-error
            [validation]="'maxlength'"
            [form]="createContactForm"
            [controlName]="'po_number'"
            [fieldLabel]="'value'"
            [length]="20"
          ></app-form-error>
          </div>

          <div class="form-group">
            <label class="form-label">Description</label>
            <textarea
              class="form-control custom"
              placeholder="Type description here"
              rows="5"
              formControlName="description"
            ></textarea>
            <app-form-error
            [validation]="'required'"
            [form]="createContactForm"
            [controlName]="'description'"
            [fieldLabel]="'Description'"
          ></app-form-error>
            <app-form-error
            [validation]="'minlength'"
            [form]="createContactForm"
            [controlName]="'description'"
            [fieldLabel]="'Description'"
            [length]="10"
          ></app-form-error>
            <app-form-error
            [validation]="'maxlength'"
            [form]="createContactForm"
            [controlName]="'description'"
            [fieldLabel]="'Description'"
            [length]="200"
          ></app-form-error>
          </div>

          <!-- Amount -->
          <div class="row form-group">
            <div class="col-6">
              <div class="form-group position-relative">
                <label class="form-label">Amount</label>
                <input
                  sflIsNumber
                  class="form-control custom"
                  required
                  placeholder="Enter Amount"
                  formControlName="amount"
                  step="0.01"
                  (input)="addingCommasToAmount($event)"
                />
                <img
                  class="postion-dolor-amount"
                  [src]="imageConst.dollarAmount"
                  alt="dolor amount"
                />
                <app-form-error
                  [validation]="'required'"
                  [form]="createContactForm"
                  [controlName]="'amount'"
                  [fieldLabel]="'Amount'"
                ></app-form-error>
              </div>
            </div>

            <!-- Description -->

            <!-- End Date -->
            <div class="col-6">
              <ng-container>
                <div class="form-group first pb-0">
                  <label class="form-label">End Date</label>
                </div>
                <div class="form-group pt-0 range-calender">
                  <p-calendar
                    appendTo="body"
                    placeholder="Select Date"
                    class="form-control custom p-0"
                    [showIcon]="true"
                    inputId="date"
                    formControlName="termination_date"
                  >
                  </p-calendar>
                </div>
                <app-form-error
                  [validation]="'required'"
                  [form]="createContactForm"
                  [controlName]="'termination_date'"
                  [fieldLabel]="'End Date'"
                ></app-form-error>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div
        class="form-group mt-5 d-flex flex-wrap justify-content-end align-items-center mt-2"
      >
        <button
          id="addContactCancel"
          type="button"
          class="btn-cancel"
          (click)="onClose()"
        >
          Cancel
        </button>
        <button
          id="addContactSubmit"
          type="submit"
          [isSubmitting]="isSubmitting"
          class="btn-save"
        >
          {{ addorUpdateButton }}
        </button>
      </div>
    </form>
  </div>
</div>
