export const ImageConstant = {
  approvedIcon: './assets/media/svg/icons/correct.svg',
  removeIcon: './assets/media/svg/icons/delete.png',
  editIcon: './assets/media/svg/icons/edit-pen.svg',
  approveCalender: './assets/media/svg/icons/approved.svg',
  rejectCalender: './assets/media/svg/icons/Rejected.svg',
  dollarAmount: './assets/media/svg/icons/dollarAmount-amount.svg',
  purchaseOrderCal :'./assets/media/svg/icons/purchase-order-calender.svg',
  purchaseOrderIcon: './assets/media/svg/icons/clear-filter.svg'
}
