import { ContactPersonService } from '@shared/components/contact-person/contact-person.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerContact, ButtonParams } from '@shared/models';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { SidebarParams } from '@shared/models/sidebar-params.model';
import { Client, Contact } from '@entities/client/client.model';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { AlertType } from '@shared/models/alert-type.enum';
import { Project } from '@entities/project/project.model';
import { PurchaseOrderService } from '@shared/services/purchase-order.service';
import { AddPurchaseOrder, addPurchaseOrderBody, PurchaseOrderWrapper } from '@shared/models/custom/purchase-order.model';
import { Customer } from '../../../entities/manage-people/manage-time/mange-time.model';
import { DatePipe } from '@angular/common';
import { AddCommasToNumbersPipe } from '@shared/pipes/add-commas-to-numbers.pipe';

@Component({
  selector: 'app-add-edit-purchase-order',
  templateUrl: './add-edit-purchase-order.component.html',
  styleUrls: ['./add-edit-purchase-order.component.scss'],
  providers: [DatePipe, AddCommasToNumbersPipe]
})
export class AddEditPurchaseOrderComponent extends SflBaseComponent implements OnInit {
  @Output() closeSidebarEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() updatePurchaseOrderList: EventEmitter<boolean> = new EventEmitter();
  @Input() sidebarParams: SidebarParams<CustomerContact>;
  @Input() purchaseOrder: PurchaseOrderWrapper;
  @Input() isEdit :boolean;
  @Input() selectedContact: Contact;
  @Input() clientId : string | number;
  @Input() project: Project;
  cardTitle: string;
  addorUpdateButton: string;
  cardSubTitle = null;
  buttons: ButtonParams[] = [
    {
      btnClass: 'btn-close-icon',
      btnIcon: 'times',
      action: this.onClose.bind(this),
    },
  ];
  createContactForm: FormGroup;

  constructor(
    private readonly contactPersonService: ContactPersonService,
    private readonly layoutUtilsService: LayoutUtilsService,
    private readonly purchaseOrderService: PurchaseOrderService,
    private readonly  datePipe :DatePipe,
    private readonly addCommasToNumber: AddCommasToNumbersPipe
  ) {
    super();
  }

  initForm() : void {
    this.createContactForm = new FormGroup({
      po_number: new FormControl('',[Validators.required,Validators.minLength(3),Validators.maxLength(20)]),
      description: new FormControl('',[Validators.required,Validators.minLength(10),Validators.maxLength(200)]),
      amount: new FormControl(''),
      termination_date: new FormControl('', [Validators.required]),
      customer_id: new FormControl(Number(this.clientId), [Validators.required]),
    });
    this.patchPurchaseOrderForm();
  }
  patchValuesForContact() : void {
    if (this.selectedContact) {
      this.createContactForm.patchValue(this.selectedContact.contact);
    }
  }

  ngOnInit(): void {
    if(this.isEdit){
      this.cardTitle = 'Edit Purchase Order';
      this.addorUpdateButton = 'Update';
    }else{
      this.cardTitle = 'Add Purchase Order';
      this.addorUpdateButton = 'Add';
    }
    this.initForm();
  }

  onClose(updateList: boolean =false) : void {
    this.isSubmitting = false;
    this.closeSidebarEvent.next(true);
    if(updateList){
      this.updatePurchaseOrderList.next(true);
    }
  }

  onSubmit() : void {
    if (!this.checkFormForValidation(this.createContactForm)) {
      const payload = {...this.createContactForm.value, amount: this.getAmount(this.createContactForm.controls['amount'].value), termination_date : this.datePipe.transform(this.createContactForm.controls['termination_date'].value, this.appConstants.format)};
      if (this.isEdit) {
        this.updatePurchaseOrder(payload)
      } else {
        this.addPurchaseOrder(payload);
      }
    }
  }

  getAmount(amount: string ): number{
    const rawValue = amount.replace(/,/g, '');
    return Number(rawValue);
  }

  addPurchaseOrder(payload: AddPurchaseOrder): void {
  this.subscriptionManager.add(
    this.purchaseOrderService.addPurchaseOrder(payload).subscribe({
      next: () => {
        this.layoutUtilsService.showActionNotification('Purchase Order added successfully', AlertType.Success);
          this.onClose(true);
      },
      error: (e) => {
        this.layoutUtilsService.showActionNotification('There was an error while adding Purchase Order details', AlertType.Error);
      },
    })
  );
  }
  updatePurchaseOrder(payload: AddPurchaseOrder): void {
  this.subscriptionManager.add(
    this.purchaseOrderService.updatePurchaseOrder(Number(this.purchaseOrder.purchase_order.id),payload).subscribe({
      next: () => {
        this.layoutUtilsService.showActionNotification('Purchase Order updated successfully', AlertType.Success);
          this.onClose(true);
      },
      error: (e) => {
        this.layoutUtilsService.showActionNotification('There was an error while adding Purchase Order details', AlertType.Error);
      },
    })
  );
  }

  patchPurchaseOrderForm() : void{
    if(this.isEdit){
      this.createContactForm.patchValue({
        ...this.purchaseOrder.purchase_order,
        amount: this.addCommasToNumber.transform(this.purchaseOrder?.purchase_order?.amount),
        termination_date: new Date(this.purchaseOrder?.purchase_order?.termination_date)
      })
    }
  }

  addingCommasToAmount(event: any): void {
    const rawValue = event.target.value.replace(/,/g, '');
    const number = Number(rawValue);
    const formattedValue = this.addCommasToNumber.transform(number);
    event.target.value = formattedValue;
  }
}
