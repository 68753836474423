import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OGantryHttpResponse } from '@shared/models';
import { AddPurchaseOrder, PurchaseOrderList, PurchaseOrderQueryParam } from '@shared/models/custom/purchase-order.model';
import { ApiUrl } from '@shared/constants';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {

  constructor(private readonly http: HttpClient) { }




  addPurchaseOrder(projectSetup: AddPurchaseOrder): Observable<OGantryHttpResponse<AddPurchaseOrder>> {
    return this.http.post<OGantryHttpResponse<AddPurchaseOrder>>(ApiUrl.purchaseOrder, { purchase_order: projectSetup });
  }
  updatePurchaseOrder(id: number,projectSetup: AddPurchaseOrder): Observable<OGantryHttpResponse<AddPurchaseOrder>> {
    return this.http.put<OGantryHttpResponse<AddPurchaseOrder>>(`${ApiUrl.purchaseOrder}/${id}`, { purchase_order: projectSetup });
  }

  purchaseOrderList(queryParam: any): Observable<OGantryHttpResponse<PurchaseOrderList>> {
  return this.http.get<OGantryHttpResponse<PurchaseOrderList>>(ApiUrl.purchaseOrder,{ params: queryParam});
  }
  purchaseOrderListBYCustomer(id: number): Observable<OGantryHttpResponse<PurchaseOrderList>> {
  return this.http.get<OGantryHttpResponse<PurchaseOrderList>>(`${ApiUrl.purchaseOrderCustomer}/${id}`);
  }

  purchaseOrderDelete(id: number): Observable<OGantryHttpResponse<PurchaseOrderList>> {
  return this.http.delete<OGantryHttpResponse<PurchaseOrderList>>(`${ApiUrl.purchaseOrder}/${id}`);
  }


}
