
<div class="my-2" *ngFor="let purchase_order of listOFPurchaseOrder;let i=index" >
  <div class="">
    <div class="purchase-order-wrapper">
      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-nowrap flex-row justify-content-between">
            <span >
              <Strong>PO Number : {{purchase_order?.purchase_order
                ?.po_number}}</Strong>
            </span>
            <span >
              <img
              class="postion-dolor-amount"
              [src]="imageConst.dollarAmount"
              alt="dolor amount"
            />
              <Strong class="pl-2">{{purchase_order?.purchase_order?.amount | addCommasToNumbers}}</Strong>
            </span>
            <span>
              <img
              class="postion-dolor-amount"
              [src]="imageConst.purchaseOrderCal"
              alt="dolor amount"
            />
              <Strong class="pl-2">{{purchase_order?.purchase_order?.termination_date}}</Strong>
            </span>
          </div>
        </div>
      </div>
      <div class="row py-4">
        <div class="col-9">
          <div class="py-2">
            {{purchase_order?.purchase_order.description}}
          </div>
        </div>
        <div class="col-3 align-content-center">
          <div class="del-icon">
            <!-- <span class="primary-contact">{{contactPerson?.contact?.is_primary ? 'PRIMARY' : ''}}</span> -->
            <a href="javascript:;void" (click)="editPurchaseOrder(purchase_order)" title="Update Purchase Order" class="btn btn-icon btn-light btn-icon-light btn-hover-primary btn-sm">
              <span title="Update Contact Person" [inlineSVG]="'assets/media/svg/icons/edit.svg'" cacheSVG="true" class="svg-icon svg-icon-md">
              </span>
            </a>
            <a href="javascript:;void" (click)="deletePurchaseOrderPopup(purchase_order?.purchase_order?.id)"  title="Delete Purchase Order"
              class="btn btn-icon btn-light btn-icon-light btn-hover-primary btn-sm">
              <span title="Delete Contact Person" [inlineSVG]="'assets/media/svg/icons/delete.svg'" cacheSVG="true" class="svg-icon svg-icon-md">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>


  <p-dialog header="Warning" [(visible)]="showDialogBox" [modal]="true" class="confirm-dialog-expense" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <h5 class="p-m-0">Are you sure you want to delete this Purchase Order?  </h5>
    <ng-template pTemplate="footer">
      <div class="d-flex flex-wrap justify-content-end align-items-center">
        <button type="button" class="btn-cancel" (click)="closeModal()">Cancel</button>
        <button type="button" class="btn-save" (click)="deletePurchaseOrder(this.deletePurchaseOrderID)" [isSubmitting]="isSubmitting">Delete</button>
      </div>
    </ng-template>
  </p-dialog>
