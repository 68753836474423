
export const FilterDataMap = {
    "Manage Clients": "manageClientFilterData",
    "Manage Projects": "manageProjectFilterData",
    "Utilization Report": "utilizationFilterData",
    "Lost Revenue": "openPositionFilterData",
    "Positions Report": "staffedPositionFilterData",
    "Manage P&L": "plFilterData",
    "P&L Comparison": "PandLComparisonFilterData",
    "Manage Employees": "ManageEmployeesFilterData",
    "Manage Time": "ManageTimeFilterData"
};

export const ReportsPathList = {
    "Manage Clients": "/client/manage",
    "Manage Projects": "/project/manage",
    "Utilization Report": "/utilization-management/bench-report",
    "Lost Revenue": "/utilization-management/lost-revenue",
    "Positions Report": "/utilization-management/positions-report",
    "Manage P&L": "financial-forecasting/manage-P&L",
    "P&L Comparison": "/financial-forecasting/compare-P&L",
    "Manage Employees": "/people/employee/manage",
    "Manage Time": "/people/manage-time"
  };