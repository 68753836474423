import { PermissionModules } from "@shared/models/permission.enum";

export class MenuConfig {
  public defaults: any = {
    aside: {
      self: {},
      items: [
        {
          title: "Manage people",
          root: true,
          icon: "ManageEmployees",
          toggle: 'click',
          id: '6',
          submenu: [
            {
              title: 'Create Employee',
              page: 'people/employee/create',
              permissionModules: [PermissionModules.MANAGE_EMPLOYEE],
              requestObject: {
                resource: 'employees'
              }
            },
            {
              title: 'Manage Employees',
              page: '/people/employee/manage',
              permissionModules: [PermissionModules.VIEW_EMPLOYEE],
              requestObject: {
                resource: 'employees'
              }
            },
            {
              title: 'Manage Work Exceptions',
              page: '/people/work-exceptions/manage',
              permissionModules: [PermissionModules.VIEW_WORK_EXCEPTION]
            },
            {
              title: 'Import Actuals',
              page: '/people/import-actuals',
              permissionModules: [PermissionModules.VIEW_IMPORT_ACTUALS]
            },
            {
              title: 'Timesheet Dashboard',
              page: '/people/timesheet-dashboard',
              permissionModules: [PermissionModules.View_Create_Entries],
            },
            {
              title: 'Manage Time',
              page: '/people/manage-time',
              permissionModules: [PermissionModules.VIEW_TIME_ENTRIES],
              requestObject: {
                resource: 'manageTime'
              }
            },
            // {
            //   title: 'Create Timesheet',
            //   page: '/people/create-timesheet',
            //   permissionModules: [PermissionModules.Create_TIME_ENTRIES],
            // }
            {
              title: 'Create Timesheet',
              page: '/people/create-timesheet',
              permissionModules: [PermissionModules.View_Create_Entries],
            }
          ]
        },
        {
          title: "Project Staffing & Estimating",
          root: true,
          icon: "projectStaffing",
          toggle: 'click',
          id: '1',
          submenu: [
            {
              title: 'Manage Clients',
              page: '/client/manage',
              permissionModules: [PermissionModules.VIEW_CLIENT],
              requestObject: {
                resource: 'customers'
              }
            },
            {
              title: 'Create Client',
              page: '/client/create',
              permissionModules: [PermissionModules.MANAGE_CLIENT]
            },
            {
              title: 'Manage Projects',
              page: '/project/manage',
              permissionModules: [PermissionModules.VIEW_PROJECT],
              requestObject: {
                resource: 'projects'
              }
            },
            {
              title: 'Create Project',
              page: '/project/create',
              permissionModules: [PermissionModules.MANAGE_PROJECT]
            },

          ]
        }
        ,
        {
          title: "Utilization Management",
          root: true,
          icon: "utilization",
          toggle: 'click',
          id: '2',
          submenu: [
            {
              title: 'Utilization Report',
              page: '/utilization-management/bench-report',
              permissionModules: [PermissionModules.VIEW_BENCH_REPORT],
              requestObject: {
                resource: 'benchReport'
              }
            },
            // TODO: feature is removed as of Now
            // {
            //   title: 'Lost Revenue',
            //   page: '/utilization-management/lost-revenue',
            //   permissionModules: [PermissionModules.VIEW_OPEN_POSITION_REPORT],
            //   requestObject: {
            //     resource: 'openPositionReport'
            //   }
            // },
            {
              title: 'Positions Report',
              page: '/utilization-management/positions-report',
              permissionModules: [PermissionModules.VIEW_STAFFED_POSITION_REPORT],
              requestObject: {
                resource: 'staffedPositionReport'
              }
            }
          ]
        },
        {
          title: "Financial Forecasting",
          root: true,
          icon: "utilization",
          toggle: 'click',
          id: '4',
          submenu: [
            {
              title: 'Manage P&L',
              page: '/financial-forecasting/manage-P&L',
              permissionModules: [PermissionModules.VIEW_PL], requestObject: {
                resource: 'managePLReport'
              }
            },
            {
              title: 'P&L Comparison',
              page: '/financial-forecasting/compare-P&L',
              permissionModules: [PermissionModules.VIEW_COMPARE_PL],
              requestObject: {
                resource: 'plReport'
              }
            }
          ]
        },
        {
          title: "Administration",
          root: true,
          icon: "administration",
          toggle: 'click',
          id: '3',
          submenu: [
            {
              title: 'Manage Tag Category',
              page: '/admin/tag-category/manage',
              permissionModules: [PermissionModules.VIEW_MANAGE_TAG_CATEGORY]
            },
            {
              title: 'Manage Tag Sub Category',
              page: '/admin/tag-sub-category/manage',
              permissionModules: [PermissionModules.VIEW_MANAGE_TAG_SUB_CATEGORY]
            },
            {
              title: 'Manage Tag',
              page: '/admin/tag/manage',
              permissionModules: [PermissionModules.VIEW_MANAGE_TAG_SUB_CATEGORY]
            },
            {
              title: 'Manage Daily Expense Types',
              page: '/admin/expense-type/manage',
              permissionModules: [PermissionModules.VIEW_EXPENSE_TYPE]
            },
            {
              title: 'Manage Monthly Expense Types',
              page: '/admin/monthly-expense-type/manage',
              permissionModules: [PermissionModules.VIEW_MONTHLY_EXPENSE_TYPE]
            },
            {
              title: 'Manage Work Exception Types',
              page: '/admin/work-exception-type/manage',
              permissionModules: [PermissionModules.VIEW_WORK_EXCEPTION_TYPE]
            },
            {
              title: 'Manage Holidays',
              page: '/admin/holidays/manage',
              permissionModules: [PermissionModules.VIEW_HOLIDAY]
            },
            {
              title: 'Manage Employee Types',
              page: '/admin/employees-type/manage',
              permissionModules: [PermissionModules.VIEW_EMPLOYEE_TYPE]
            },
            {
              title: 'Manage Skill Sets',
              page: '/admin/position-type/manage',
              permissionModules: [PermissionModules.VIEW_POSITION_TYPE]
            },
            {
              title: 'Manage Extended Fields ',
              page: '/admin/extended-Fields/manage',
              permissionModules: [PermissionModules.View_Extended_Filed]
            },
          ]
        },
        {
          title: "Access Control",
          root: true,
          icon: "shield",
          toggle: 'click',
          id: '5',
          submenu: [
            {
              title: 'Manage Users',
              page: '/access-control/manage-user',
              permissionModules: [PermissionModules.VIEW_MANAGE_USER]
            },
            {
              title: 'Manage Permissions',
              page: '/access-control/role/manage',
              permissionModules: [PermissionModules.VIEW_ROLE]
            },
          ]
        },
        // {
        //   title: "Users",
        //   root: true,
        //   bullet: "dot",
        //   icon: "flaticon2-user-outline-symbol",
        //   translate: "MENU.USERS",
        //   page: "/users",
        // },
        // { section: "Custom" },
        // {
        //   title: "Error",
        //   root: true,
        //   bullet: "dot",
        //   icon: "flaticon2-list-2",
        //   page: "/error",
        // }
      ],
    },
  };

  public get configs(): string {
    return this.defaults;
  }
}
