import { BaseComponent } from './../base/base.component';
/**
 * @sunflowerlab
 * @author Siddhi Shah
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { filter } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import * as objectPath from "object-path";
// Layout
import { HtmlClassService } from "../html-class.service";
import { OffcanvasOptions } from "@shared/directives/offcanvas.directive";
import { MenuOptions } from "@shared/directives/menu.directive";
import { MenuAsideService } from "@shared/services/menu-aside.service";
import { LayoutConfigService } from "@shared/services/layout-config.service";
import { LayoutConstants } from "../../partials/layout/layout.constants";
import { ToggleOptions } from "@shared/directives/toggle.directive";
import { OverlayPanel } from "primeng/overlaypanel";
import { UtilizationService } from "@entities/utilization-management/utilization.service";
import { ISavedFilterList, QueryFilter } from "@entities/administration/administration.model";
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { FilterDataMap, ReportsPathList } from './aside-left.model';

@Component({
  selector: "kt-aside-left",
  templateUrl: "./aside-left.component.html",
  styleUrls: ["./aside-left.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideLeftComponent extends SflBaseComponent implements OnInit, AfterViewInit {
  private offcanvas: any;

  @ViewChild("asideMenuOffcanvas", { static: true })
  asideMenuOffcanvas: ElementRef;
  @ViewChild("asideMenu", { static: true }) asideMenu: ElementRef;

  asideLogo = "";
  asideClasses = "";
  currentRouteUrl = "";
  previousActiveMenu = [];
  insideTm: any;
  outsideTm: any;
  toggleOptions: ToggleOptions = {
    target: KTUtil.getBody(),
    targetState: "topbar-mobile-on",
    toggleState: "active",
  };
  menuCanvasOptions: OffcanvasOptions = {
    baseClass: "aside",
    overlay: true,
    closeBy: "kt_aside_close_btn",
    toggleBy: {
      target: "kt_aside_mobile_toggle",
      state: "mobile-toggle-active",
    },
  };
  @ViewChild("popup", { static: true }) popup: OverlayPanel;
  filterList: QueryFilter[] = [];
  title: string;

  menuOptions: MenuOptions = {
    // submenu setup
    submenu: {
      desktop: {
        // by default the menu mode set to accordion in desktop mode
        default: "dropdown",
      },
      tablet: "accordion", // menu set to accordion in tablet mode
      mobile: "accordion", // menu set to accordion in mobile mode
    },

    // accordion setup
    accordion: {
      expandAll: false, // allow having multiple expanded accordions in the menu
    },
  };
  defaultView = [];
  manageClientFilterData: QueryFilter[];
  manageProjectFilterData: QueryFilter[];
  utilizationFilterData: QueryFilter[];
  openPositionFilterData: QueryFilter[];
  staffedPositionFilterData: QueryFilter[];
  plFilterData: QueryFilter[];
  PandLComparisonFilterData: QueryFilter[];
  ManageEmployeesFilterData: QueryFilter[];
  filterDataMap = FilterDataMap;

  constructor(
    public readonly htmlClassService: HtmlClassService,
    public readonly menuAsideService: MenuAsideService,
    public readonly layoutConfigService: LayoutConfigService,
    private readonly router: Router,
    private readonly render: Renderer2,
    private readonly cdr: ChangeDetectorRef,
    private readonly utilizationService: UtilizationService
  ) { super(); }

  ngAfterViewInit(): void { }

  ngOnInit() {
    this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
        this.mobileMenuClose();
        this.cdr.markForCheck();
      });

    const config = this.layoutConfigService.getConfig();

    if (objectPath.get(config, "aside.menu.dropdown")) {
      this.render.setAttribute(
        this.asideMenu.nativeElement,
        "data-ktmenu-dropdown",
        "1"
      );
      // tslint:disable-next-line:max-line-length
      this.render.setAttribute(
        this.asideMenu.nativeElement,
        "data-ktmenu-dropdown-timeout",
        objectPath.get(config, "aside.menu.submenu.dropdown.hover-timeout")
      );
    }

    this.asideClasses = this.htmlClassService
      .getClasses("aside", true)
      .toString();
    this.asideLogo = this.getAsideLogo();
    setTimeout(() => {
      this.offcanvas = new KTOffcanvas(
        this.asideMenuOffcanvas.nativeElement,
        this.menuCanvasOptions
      );
    });

    this.utilizationService.showNewSharedFilter.subscribe((res) => {
      const filterProperty = this.filterDataMap[res];
      if (filterProperty) {
        this[filterProperty] = undefined;
      }
    });
  }

  getAsideLogo() {
    return `./assets/media/logos/ogantryLogo.png`;
  }

  /**
   * Check Menu is active
   * @param item: any
   */
  isMenuItemIsActive(item): boolean {
    if (item.submenu) {
      return this.isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }

    return this.currentRouteUrl.indexOf(item.page) !== -1;
  }

  /**
   * Check Menu Root Item is active
   * @param item: any
   */
  isMenuRootItemIsActive(item): boolean {
    let result = false;

    for (const subItem of item.submenu) {
      result = this.isMenuItemIsActive(subItem);
      if (result) {
        return true;
      }
    }

    return false;
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter(e: Event) {
    // check if the left aside menu is fixed
    if (document.body.classList.contains("aside-fixed")) {
      if (this.outsideTm) {
        clearTimeout(this.outsideTm);
        this.outsideTm = null;
      }

      this.insideTm = setTimeout(() => {
        // if the left aside menu is minimized
        if (
          document.body.classList.contains(LayoutConstants.asideMinimize) &&
          KTUtil.isInResponsiveRange("desktop")
        ) {
          // show the left aside menu
          this.render.removeClass(document.body, LayoutConstants.asideMinimize);
          this.render.addClass(
            document.body,
            LayoutConstants.asideMinimizeHover
          );
        }
      }, 50);
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseLeave(e: Event) {
    if (document.body.classList.contains("aside-fixed")) {
      if (this.insideTm) {
        clearTimeout(this.insideTm);
        this.insideTm = null;
      }

      this.outsideTm = setTimeout(() => {
        // if the left aside menu is expand
        if (
          document.body.classList.contains(
            LayoutConstants.asideMinimizeHover
          ) &&
          KTUtil.isInResponsiveRange("desktop")
        ) {
          // hide back the left aside menu
          this.render.removeClass(
            document.body,
            LayoutConstants.asideMinimizeHover
          );
          this.render.addClass(document.body, LayoutConstants.asideMinimize);
        }
      }, 100);
    }
  }
  submenuClick(item) {
    if (item.page) {
      this.previousActiveMenu.push(this.router.url);
    }
  }

  containsPage(submenu) {
    let exists = submenu.filter((sub) => {
      return this.previousActiveMenu.includes(sub.page);
    });
    return exists.length > 0;
  }


  /**
   * Returns Submenu CSS Class Name
   * @param item: any
   */
  getItemCssClasses(item) {
    let classes = "menu-item";

    if (objectPath.get(item, "submenu")) {
      classes += " menu-item-submenu";
    }

    if (!item.submenu && this.isMenuItemIsActive(item)) {
      classes += " menu-item-active menu-item-here ";
    }
    if (item.submenu && !this.isMenuItemIsActive(item)) {
      classes += " menu-item-active menu-item-here";
    }
    if (item.submenu && this.isMenuItemIsActive(item)) {
      classes +=
        " menu-item-active menu-item-here menu-item-open menu-item-here-1 ";
      item.icon = this.getActiveIcon(item);
    } else {
      item.icon = this.getIcon(item);
    }

    // custom class for menu item
    const customClass = objectPath.get(item, "custom-class");
    if (customClass) {
      classes += " " + customClass;
    }

    if (objectPath.get(item, "icon-only")) {
      classes += " menu-item-icon-only";
    }
    return classes;
  }

  getItemAttrSubmenuToggle(item) {
    let toggle = "hover";
    if (objectPath.get(item, "toggle") === "click") {
      toggle = "click";
    } else if (objectPath.get(item, "submenu.type") === "tabs") {
      toggle = "tabs";
    } else {
      // submenu toggle default to 'hover'
    }

    return toggle;
  }

  mobileMenuClose() {
    if (KTUtil.isBreakpointDown("lg") && this.offcanvas) {
      // Tablet and mobile mode
      this.offcanvas.hide(); // Hide offcanvas after general link click
    }
  }

  getMenuIcon(item) {
    if (item.submenu && this.isMenuItemIsActive(item)) {
      return this.getActiveIcon(item);
    } else {
      return this.getIcon(item);
    }
  }

  getIcon(item) {
    switch (item?.id) {
      case "1":
        return `./assets/media/svg/icons/projectStaffing.svg`;
      case "2":
        return `./assets/media/svg/icons/utilization.svg`;
      case "3":
        return `./assets/media/svg/icons/administration.svg`;
      case "4":
        return `./assets/media/svg/icons/financialForecasting.svg`;
      case "5":
        return `./assets/media/svg/icons/shield.svg`;
      case "6":
        return `./assets/media/svg/icons/manageEmployees.svg`
    }
  }

  getActiveIcon(item) {
    switch (item?.id) {
      case "1":
        return `./assets/media/svg/icons/projectStaffingActive.svg`;
      case "2":
        return `./assets/media/svg/icons/utilizationActive.svg`;
      case "3":
        return `./assets/media/svg/icons/administrationActive.svg`;
      case "4":
        return `./assets/media/svg/icons/financialForecastingActive.svg`;
      case "5":
        return `./assets/media/svg/icons/shieldActive.svg`;
      case "6":
        return `./assets/media/svg/icons/manageEmployeesActive.svg`;
    }
  }

  onSelectFilter(val: QueryFilter) {
    const reportsPathList = ReportsPathList
    const reportPath = reportsPathList[this.title];
    if (reportPath) {
      this.navigateToReport(reportPath, val?.query_filter?.id, this.title);
    }
  }

  navigateToReport(route: string, filterId: number, reportTitle: string) {
    this.router.navigate([route], {
      queryParams: { filterId: filterId },
      queryParamsHandling: "merge",
    });
    this.popup.hide();
    this.title = reportTitle;
  }

  onShowPopup(event, menuItem) {
    if (this.popup.overlayVisible) {
      this.popup.hide();
    }
    this.title = "";

    setTimeout(() => {
      const filterProperty = this.filterDataMap[menuItem.title];
      const filterData = this[filterProperty] ? this[filterProperty] : [];
      if (this.filterDataMap.hasOwnProperty(menuItem.title)) {
        if (!this[filterProperty]) {
          this.makeApiCall(menuItem, menuItem.title, event);
        } else {
          this.filterList = filterData;
          if (this.filterList.length >= 1) {
            this.showPopup(event, menuItem.title);
          }
        }
      }
    }, 100);
  }

  makeApiCall(menuItem, title, event) {
    this.utilizationService.getStoredFilters(menuItem.requestObject).subscribe((res: ISavedFilterList) => {
      const filterListKey = this.filterDataMap[title];
      const filterData = JSON.parse(JSON.stringify(res)).data?.query_filters?.filter(
        (q) => q.query_filter.is_shared === true
      );

      if (filterData && filterData.length > 0) {
        this[filterListKey] = filterData;
        this.filterList = filterData;
        this.showPopup(event, title);
      } else {
        this[filterListKey] = [];
      }
    });
  }

  showPopup(event, title) {
    setTimeout(() => {
      this.popup.show(event, event.offsetParent);
    }, 100);
    this.title = title;
  }
}
