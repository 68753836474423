/**
 * @sunflowerlab
 * @author Ashish Kumar
 */
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorService, KtDialogService } from "./services";
import { NgModule } from "@angular/core";
import { ContentAnimateDirective } from "./directives/content-animate.directive";
import { HeaderDirective } from "./directives/header.directive";
import { MenuDirective } from "./directives/menu.directive";
import { OffcanvasDirective } from "./directives/offcanvas.directive";
import { StickyDirective } from "./directives/sticky.directive";
import { TabClickEventDirective } from "./directives/tab-click-event.directive";
import { ToggleDirective } from "./directives/toggle.directive";
import { PhoneNumberDirective } from "./directives/phone-number.directive";
import { FirstLetterPipe } from "./pipes/first-letter.pipe";
import { AddCommasToNumbersPipe } from "./pipes/add-commas-to-numbers.pipe";
import { GetObjectPipe } from "./pipes/get-object.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { MenuConfigService } from "./services/menu-config.service";
import { MenuAsideService } from "./services/menu-aside.service";
import { PageConfigService } from "./services/page-config.service";
import { SplashScreenService } from "./services/splash-screen.service";
import { SubheaderService } from "./services/subheader.service";
import { LayoutRefService } from "./services/layout-ref.service";
import { LayoutUtilsService } from "./services/layout-utils.service";
import { TranslateModule } from "@ngx-translate/core";
import { SflBaseComponent } from "./components/sfl-base/sfl-base.component";
import {
  PasswordEyeDirective,
  SflIsFetchingDirective,
  SflShowSpinnerDirective,
  ClickOutsideDirective,
  IsNumberDirective,
  HasAnyPermissionDirective,
  IsDecimalNumberDirective,
} from "@shared/directives";
import { SflLoadingComponent } from "./components/sfl-loading/sfl-loading.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CardHeaderComponent } from "./components/card-header/card-header.component";
import { ContactPersonComponent } from "./components/contact-person/contact-person.component";
import { InlineSVGModule } from "ng-inline-svg";
import { FormErrorComponent } from "./components/form-error/form-error.component";
import { PhoneMaskPipe } from "./pipes/phone-mask.pipe";
import { AddContactComponent } from "./components/contact-person/add-contact/add-contact.component";
import { SelectedFilterTagsComponent } from "./components/selected-filter-tags/selected-filter-tags.component";
import { FontAwesomeLibModule } from "./fontawesome-lib.module";
import { AuthNoticeComponent } from "@auth/auth-notice/auth-notice.component";
import { ApplyFilterComponent } from "./components/apply-filter/apply-filter.component";
import { ApplyCompareFilterComponent } from "./components/apply-compare-filter/apply-compare-filter.component";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { CalendarModule } from "primeng/calendar";
import { SplitButtonModule } from "primeng/splitbutton";
import { ForbiddenComponent } from "./components/forbidden/forbidden.component";
import { MultiSelectModule } from "primeng/multiselect";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { NewClientComponent } from "./components/client/new-client/new-client.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TagListingViewComponent } from "@entities/administration/tag-listing-view/tag-listing-view.component";
import { ChipModule } from "primeng/chip";
import { DialogModule } from "primeng/dialog";
import { SflAutofocusDirective } from "./directives/sfl-autofocus.directive";
import { TreeSelectModule } from "primeng/treeselect";
import { SharedRangeSelectorComponent } from "./components/shared-range-selector/shared-range-selector.component";
import { TimeSheetTableComponent } from "./components/time-sheet-table/time-sheet-table.component";
import { TableModule } from "primeng/table";
import { FilterTableFieldsComponent } from "./components/filter-table-fields/filter-table-fields.component";
import { ListboxModule } from "primeng/listbox";
import { FilterIconSharedComponent } from "./components/filter-icon-shared/filter-icon-shared.component";
import { ExtendedFormComponent } from "./components/extended-form/extended-form.component";
import { BookTimeOffComponent } from './components/book-time-off/book-time-off.component';
import { AddEditPurchaseOrderComponent } from "./components/add-edit-purchase-order/add-edit-purchase-order.component";
import { ListPurchaseOrderComponent } from "./components/list-purchase-order/list-purchase-order.component";

const SHARED_COMPONENTS = [
  CardHeaderComponent,
  SflBaseComponent,
  SflLoadingComponent,
  ContactPersonComponent,
  AddContactComponent,
  AuthNoticeComponent,
  ApplyFilterComponent,
  ApplyCompareFilterComponent,
  SelectedFilterTagsComponent,
  NewClientComponent,
  TagListingViewComponent,
  SharedRangeSelectorComponent,
  TimeSheetTableComponent,
  ForbiddenComponent,
  FilterTableFieldsComponent,
  FilterIconSharedComponent,
  ExtendedFormComponent,
  BookTimeOffComponent,
  AddEditPurchaseOrderComponent,
  ListPurchaseOrderComponent
];

const PIPES = [
  FirstLetterPipe,
  GetObjectPipe,
  SafePipe,
  FormErrorComponent,
  PhoneMaskPipe,
  AddCommasToNumbersPipe,
];
const DIRECTIVES = [
  ContentAnimateDirective,
  HeaderDirective,
  MenuDirective,
  OffcanvasDirective,
  StickyDirective,
  TabClickEventDirective,
  ToggleDirective,
  PasswordEyeDirective,
  SflIsFetchingDirective,
  SflShowSpinnerDirective,
  ClickOutsideDirective,
  IsNumberDirective,
  IsDecimalNumberDirective,
  HasAnyPermissionDirective,
  PhoneNumberDirective,
  SflAutofocusDirective,
];

const MODULES = [
  MatProgressSpinnerModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  InlineSVGModule,
  FontAwesomeLibModule,
  DropdownModule,
  InputTextModule,
  CalendarModule,
  SplitButtonModule,
  MultiSelectModule,
  NgbModule,
  MatIconModule,
  MatChipsModule,
  MatSlideToggleModule,
  ChipModule,
  DialogModule,
  TreeSelectModule,
  TableModule,
  ListboxModule,
];

@NgModule({
  imports: [TranslateModule.forChild(), ...MODULES],
  exports: [
    ...PIPES,
    ...SHARED_COMPONENTS,
    ...DIRECTIVES,
    TranslateModule,
    ...MODULES,
  ],
  declarations: [...SHARED_COMPONENTS, ...PIPES, ...DIRECTIVES],
  providers: [
    KtDialogService,
    MenuConfigService,
    MenuAsideService,
    PageConfigService,
    SplashScreenService,
    SubheaderService,
    LayoutRefService,
    LayoutUtilsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
})
export class SharedModule { }
