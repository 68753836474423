/**
 * @sunflowerlab
 * @author Ashish Kumar
 */
export class ApiUrl {
  public static readonly login: string = "/session";
  public static readonly account: string = "/account";
  public static readonly users: string = "/users/get_self";
  public static readonly register = "/register";
  public static readonly forgetPassword = {
    init: "/account/reset-password/init",
    finish: "/account/reset-password/finish",
  };
  public static readonly clientContact = "/contacts/customer";
  public static readonly client: string = "/customers";
  public static readonly saveFilter: string = "/query_filters";
  public static readonly project: string = "/projects";
  public static readonly purchaseOrder: string = "/purchase_orders";
  public static readonly purchaseOrderCustomer: string = "/purchase_orders/customer";
  public static readonly billingType: string = "/projects/billing_types";
  public static readonly position: string = "/positions";
  public static readonly renewSession: string = "/session/renew";
  public static readonly projectContact = "/contacts/project";
  public static readonly projectWithProjection: string =
    "/projects/plus_projection";
  public static readonly projectWithProfitLoss: string =
    "/projects/profit_loss";
  public static readonly compareProjections: string =
    "/projects/projections/compare";
  public static readonly googleAuth = "/auth/google";
  public static readonly githubAuth = "/auth/github";
  public static readonly microsoftAuth = "/auth/azuread";
  public static readonly employeeWithPosition = "/employees/with/position";
  public static readonly employee = "/employees";
  public static readonly employeeFinanacial = "/employee_financials/active";
  public static readonly dailyExpenseType = "/daily_expense_types";
  public static readonly dailyExpenses = "/daily_expenses";
  public static readonly monthlyExpenses = "/monthly_expenses";
  public static readonly benchReport = "/employees/plus_utilization";
  public static readonly employeeTypes = "/employee_types";
  public static readonly regions = "/regions";
  public static readonly globalDetails = "/global_details";
  public static readonly openPositionReport = "/positions/plus_projection";
  public static readonly projections = "/projections";
  public static readonly workExceptions = "/work_exceptions";
  public static readonly workExceptionTypes = "/work_exception_types";
  public static readonly holidayWorkExceptions = "/holidays";
  public static readonly backendBuildVersion = "/release_versions/get/latest";
  public static readonly projectStatus = "/project_statuses";
  public static readonly positionType = "/position_types";
  public static readonly monthlyExpenseType = "/monthly_expense_types";
  public static readonly checkAuthSessionStatus = "/session/check";
  public static readonly holidayTypes = "/holiday_exception_types";
  public static readonly validationStatus = "/validation_status";
  public static readonly activeEmployeePosition: string =
    "/positions/active/employee";
  public static readonly pnlPlug = "/pnl_plugs";
  public static readonly fixedBidPlug = "/fixed_bid_plugs";
  public static readonly userManagement = "/user_authorizations";
  public static readonly appendTagToAnEmployee =
    "/employees/{employee_id}/tags/add";
  public static readonly deleteTagFromAnEmployee =
    "/employees/{employee_id}/tags/delete";
  public static readonly appendTagToAClient = "/customers/{client_id}/tags/add";
  public static readonly deleteTagFromAClient =
    "/customers/{client_id}/tags/delete";
  public static readonly appendTagToAProject =
    "/projects/{project_id}/tags/add";
  public static readonly deleteTagFromAProject =
    "/projects/{project_id}/tags/delete";
  public static readonly appendTagToAPosition =
    "/positions/{position_id}/tags/add";
  public static readonly deleteTagFromAPosition =
    "/positions/{position_id}/tags/delete";
  public static readonly employeeIds = "/employees/ids";
  public static readonly retainerplugs = "/retainer_plugs";
  public static readonly timeEntries = "/time_entries";
  public static readonly timeEntriesByPostion = "/time_entries/by/position/";
  public static readonly manageTime = "/positions/plus_time_entries";
  public static readonly employeeLookup = '/employees/lookup'
  public static readonly copySame = 'copy/same';
  public static readonly activeAccrualByEmployee = '/accruals/active_by_employee';
  public static readonly calcAccruals = '/accruals/calc_accruals' 
  public static readonly accruals = '/accruals';
  public static readonly purchaseOrders = "/purchase_orders";
  public static readonly purchaseOrdersForCustomer = "/purchase_orders/customer";
}
